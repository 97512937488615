import { apiUrl } from '../config';
import { useState } from 'react';

const BookingFormComponent = ({ data, onClose }) => {  
  const [bookingData, setBookingData] = useState(data);
  const [message, setMessage] = useState(null);
  const payAtBank = async () => {
    const requestData = {
      'check_in': bookingData.check_in,
      'check_out': bookingData.check_out,
      'room_id': bookingData.room_id,
      'full_name': bookingData.full_name,
      'email': bookingData.email,
      'phone_number': bookingData.phone_number
    }
    try {
      const response = await fetch(`${apiUrl}/api/payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
      });
      const result = await response.json();
      if (result.request.status === 0) {
        window.location.href = result.request.data.formUrl;
      } else {
        setMessage(result.request.message)
      }
    } catch (error) {
      console.error("Ошибка при перенаправлении на банк: ", error)
      // console.error("Ошибка при перенаправлении на банк.")
    }
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="background-floor" id="bookingForm">
      <form className="booking-form container">
        <div className="pos-rel">
          <span className="closer-icon" onClick={onClose}>X</span>
        </div>
        {/* <!-- Имя и фамилия --> */}
        <div className="form-group">
          <label htmlFor="full_name">Ваше имя и фамилия:</label>
          <input type="text" placeholder='Введите ваше имя и фамилию' value={bookingData.full_name} id="full_name" name="full_name" required onChange={handleInputChange} />
        </div>

        {/* <!-- Электронная почта --> */}
        <div className="form-group">
          <label htmlFor="email">Электронная почта:</label>
          <input type="text" placeholder='Пожалуйста введите действующую эл. почту' value={bookingData.email} id="email" name="email" required onChange={handleInputChange} />
        </div>

        {/* <!-- Номер телефона --> */}
        <div className="form-group">
          <label htmlFor="phone_number">Номер телефона:</label>
          <input type="tel" placeholder='Введите ваш номер телефона' value={bookingData.phone_number} id="phone_number" name="phone_number" required onChange={handleInputChange} />
        </div>

        <ul className="booking-form__list-data">
          <li className="booking-form__list-data__item">
            Сумма за бронь: <span className="fw-b">{data.booking_price} TMT</span>
          </li>
          <li className="booking-form__list-data__item">
            Сумма за номер (1 сутки): <span className="fw-b">{data.price} TMT</span>
          </li>
          <li className="booking-form__list-data__item">
            Дата заезда: <span className="fw-b">{data.check_in}</span>
          </li>
          <li className="booking-form__list-data__item">
            Дата выезда: <span className="fw-b">{data.check_out}</span>
          </li>
          <li className="booking-form__list-data__item">
            Тип номера: <span className="fw-b">{data.room_type}</span>
          </li>
          <li className="booking-form__list-data__item">
            Описание: <span>{data.description}</span>
          </li>
          <li className="booking-form__list-data__item">
            Количество гостей которые могут разместиться в номере: <span className="fw-b">{data.capacity}</span>
          </li>
          <li className="booking-form__list-data__item">
            Количество комнат в номере: <span className="fw-b">{data.rooms_count}</span>
          </li>
          <li className="booking-form__list-data__item">
            Оплата производится онлайн, а также оплачиваются первые сутки
            проживания. За следующие сутки нужно произвести оплату на ресепшн.
            {/* В случае отмены брони за 72 часа, стоимость за
            проживание первых суток возвращается. */}
          </li>
          <li className="booking-form__list-data__item">Курение запрещено</li>
          <li className="booking-form__list-data__item">
            Нельзя проводить вечеринки/мероприятия
          </li>
        </ul>
        <h3 className='error-message'>{message && message}</h3>
        {/* <!-- Кнопка для подтверждения бронирования --> */}
        <button type="button" className="button-booking" onClick={() => payAtBank(bookingData)}>
          Подтвердить бронирование (переход на станицу банка)
        </button>
      </form>
    </div>
  )
}

export default BookingFormComponent;