import React, { useState } from 'react';


const RoomView = ({ room, onClose }) => {
    const [viewRoom] = useState(room);
    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <header className="system-header">Просмотр</header>
                <div className="system-form">
                    <div className="system-input-box">
                        <label>Тип номера</label>
                        <input disabled placeholder="Строка" value={viewRoom.room_type} type="text" name='room_type' />
                    </div>
                    <div className="system-input-box">
                        <label>Количество номеров</label>
                        <input disabled placeholder="Число" value={viewRoom.count} type="number" name='count' />
                    </div>
                    <div className="system-input-box">
                        <label>Описание</label>
                        <input disabled placeholder="Описание" value={viewRoom.description ? viewRoom.description : ''} type="text" name='description' />
                    </div>
                    <div className="system-input-box">
                        <label>Цена, летний сезон</label>
                        <input disabled placeholder="Число" value={viewRoom.price_summer} type="number" name='price_summer' />
                    </div>
                    <div className="system-input-box">
                        <label>Цена, зимний сезон</label>
                        <input disabled placeholder="Число" value={viewRoom.price_winter} type="number" name='price_winter' />
                    </div>
                    <div className="system-input-box">
                        <label>Максимальное количество гостей, которые могут разместиться в номере</label>
                        <input disabled placeholder="Число" value={viewRoom.capacity} type="number" name='capacity' />
                    </div>
                    <div className="system-input-box">
                        <label>Количество комнат в номере</label>
                        <input disabled placeholder="Число" value={viewRoom.rooms_count} type="number" name='rooms_count' />
                    </div>
                    <div className='flex-btns'>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RoomView;