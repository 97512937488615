import React, { useEffect, useState } from 'react';
// import Dashboard from './Dashboard';

import { intervalUpdatingData } from '../../utils/dynamic';

import { fetchData } from '../../utils/api';
import { apiUrl } from '../../config'
import UserView from '../../components/system/users/UserView';
import UserEdit from '../../components/system/users/UserEdit';
import UserNew from '../../components/system/users/UserNew';

const Users = () => {
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [usersMessage, setUsersMessage] = useState([])
    const [loading, setLoading] = useState(false); // состояние для отслеживания загрузки

    const [isEditOpen, setIsEditOpen] = useState(false); // состояние для показа формы редактирования записи
    const [isViewOpen, setIsViewOpen] = useState(false); // состояние для просмотра записи
    const [isNewOpen, setIsNewOpen] = useState(false); // состояние для создания новой записи записи
    const [editData, setEditData] = useState([]); // состояние с данными для редактирования записи
    const [viewData, setViewData] = useState([]); // состояние с данными для просмотра записи

    // Handle VIEW/EDIT/DELETE click
    const handleVEDNClick = (user, action) => {
        switch (action) {
          case 'view':
            setViewData(user)
            setIsViewOpen(true);
            break;
          case 'edit':
            setEditData(user)
            setIsEditOpen(true);
            break;
          case 'new':
            setIsNewOpen(true);
            break;
          default:
            break
        }
    };
    // Handle VIEW/EDIT closer
    const handleVEClose = () => {
        setIsViewOpen(false);
        setIsEditOpen(false);
        setIsNewOpen(false);
    };

    const fetchUsers = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const data = await fetchData(`${apiUrl}/api/sys/get-users`, "GET", token)
          if (data.request.status === 0) {
              // Обработка данных и связывание booking с соответствующим room
              setUsers(
                  data.request.data.users.map((user) => {
                      const role = data.request.data.roles.find((role) => role.id === user.role_id);
                      return {
                          ...user,
                          role_name: role ? role.role_name : 'Неизвестно', // Тип номера
                      };
                  })
              );
              setRoles(data.request.data.roles)
              setLoading(false); // Останавливаем загрузку
        } else {
            setUsersMessage(data.request.message);
        }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    };


  useEffect(() => {
    fetchUsers();
    // Обновление данных каждые 10 секунд
    const interval = setInterval(() => {
      setLoading(true)
      setTimeout(() => {
        fetchUsers();
      }, 3000)
    }, intervalUpdatingData);
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, []);

  return (
      <>
        {/* <Dashboard /> */}
        <section className="system-bookings-table">
          <div className='d-flex just-content-sb'>
            {/* <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(null, 'new')}>+</button> */}
            <button className="system-booking-table-btn system-booking-table-btn-view">+</button>
            {loading && (
              <div className="loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            )}
          </div>

          <table className='system-table'>
            <thead className='system-thead'>
              <tr className='system-tr'>
                <th className='system-th'>№</th>
                <th className='system-th'>Login</th>
                <th className='system-th'>Роль</th>
                <th className='system-th'>Действия</th>
              </tr>
            </thead>
            <tbody className='system-tbody'>
            {users && users.length > 0 ? (
              <React.Fragment>
              {users.map((user, index) => (
                <tr className="system-tr" key={index + 1}>
                  <td className="system-td">{user.id}</td>
                  <td className="system-td">{user.username}</td>
                  <td className="system-td">{user.role_name}</td>
                  <td className="system-td">
                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(user, 'view')}>Посмотреть</button>
                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(user, 'edit')}>Изменить</button>
                    {/* <button className="system-booking-table-btn system-booking-table-btn-cancel">Отменить</button> */}
                  </td>
                </tr>
              ))}
              </React.Fragment>
            ) : (
              <tr>
                <td className="system-td">{usersMessage}</td>
              </tr>
            )}
            </tbody>
          </table>
        </section>
        {isViewOpen && (
          <UserView user={viewData} onClose={handleVEClose} />
        )}
        {isEditOpen && (
          <UserEdit user={editData} rolesData={roles} onClose={handleVEClose} />
        )}
        {isNewOpen && (
          <UserNew rolesData={roles} onClose={handleVEClose} />
        )}
      </>
  );
};

export default Users;