import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';


const BookingNew = ({ onClose }) => {
    const [messageNewData, setMessageNewData] = useState([])
    const [rooms, setRooms] = useState([])
    const [selectedPrice, setSelectedPrice] = useState('')
    const [newBooking, setNewBooking] = useState({
        'full_name': '',
        'phone_number': '',
        'email': '',
        'available_from': '',
        'available_to': '',
        'room_id': ''
    });
    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)            
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBooking((prevBooking) => ({ ...prevBooking, [name]: value }));

        if (name === 'room_id') {
            // Находим цену для выбранной комнаты и обновляем состояние
            const selectedRoom = rooms.find((room) => room.id === parseInt(value));
            setSelectedPrice(selectedRoom ? selectedRoom.price : ''); // Просто для отрисовки на странице
        }
    };

    useEffect(() => {
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && newBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(newBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, newBooking.room_id])

    const sendData = async (dataEdit) => {
        const requestData = dataEdit
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiUrl}/api/sys/new-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });
            const data = await response.json();
            if (response.ok) {
                setMessageNewData(data.request.message)
            } else {
                setMessageNewData(data.request.message)
                console.error("Ошибка загрузки данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    }
    
    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <header className="system-header">Новая бронь</header>
                <form className="system-form">
                    <div className="system-input-box">
                        <label>Полное имя</label>
                        <input required placeholder="Имя Фамилия" value={newBooking.full_name} type="text" name='full_name' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Телефон</label>
                        <input required placeholder="Введите номер тел." value={newBooking.phone_number} type="telephone" name="phone_number" onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Почта</label>
                        <input required placeholder="Введите почту" value={newBooking.email} type="telephone" name="email" onChange={handleInputChange} />
                    </div>
                    <div className="system-column">
                        <div className="system-input-box">
                            <label>Начало брони</label>
                            <input required value={newBooking.available_from} placeholder="Заезд" type="date" name='available_from' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Окончание брони</label>
                            <input required value={newBooking.available_to} placeholder="Выезд" type="date" name='available_to' onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="system-input-box system-address">
                        <label>Тип номера</label>
                        <div className="system-column">
                            <div className="system-select-box">
                                <select required value={newBooking.room_id || ''} onChange={handleInputChange} name='room_id'>
                                    <option value="0">Не выбрано</option>
                                    {rooms && rooms.length > 0 ? (
                                        rooms.map((room) => (
                                            <option key={room.id} value={room.id}>{room.room_type}</option>
                                        ))
                                    ) : (
                                        <option value="">Нет номеров</option>
                                    )}

                                </select>
                            </div>
                            <input placeholder={selectedPrice || "Цена не доступна"} disabled type="text" />
                        </div>
                    </div>
                    <div className='flex-btns'>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(newBooking)}>Сохранить</button>
                    </div>
                    {Array.isArray(messageNewData) && messageNewData.length > 0 ? (
                        messageNewData.map((mess, index) => (
                            <span className='error-message' key={index + 1}>{mess}</span>
                        ))
                    ) : (null)}
                </form>
                </section>
            </div>
    );
}

export default BookingNew;