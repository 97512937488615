import React from 'react';

const services = [
  {
    title: 'Аптека',
    image: './media/img/services/pharmacy.jpg',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  },
  {
    title: 'Завтрак',
    image: './media/img/services/breakfast.jpg',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  },
  {
    title: 'Бассейн',
    image: './media/img/services/pool.jpg',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  },
  // {
  //   title: 'Удобства для детей',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  // {
  //   title: 'Сауна',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  // {
  //   title: 'Хаммам',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  // {
  //   title: 'Аквапарк',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  // {
  //   title: 'Массаж',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  // {
  //   title: 'Бильярд',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  // {
  //   title: 'Игровой зал',
  //   image: './media/img/services/pic1l.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // },
  {
    title: 'Доступ в Интернет',
    image: './media/img/services/pic3wifi.jpg',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  },
  // {
  //   title: 'Тренажерный зал',
  //   image: './media/img/services/pic2tren.jpg',
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi soluta doloremque necessitatibus nihil nisi, provident deleniti.'
  // }
];


const ServicesComponent = () => {
  return (
    <React.Fragment>
      <section className="services-section" id='services'>
        <h2 className="services-title">Наши услуги</h2>
        <div className="services-grid">
          {services.concat(services).map((item, index) => (
            <div key={index} className="services-card">
              <img src={item.image} alt={item.title} className="services-image" />
              <h3 className="services-card-title">{item.title}</h3>
              <p className="services-description">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <main className="container main-services-blocks">
        <section className="main-services-block" id="restaurant">
          <h3 className="services-block-title underline-title">Ресторан</h3>

          <div className="main-services-block_it">
            <img
              src={'./media/img/services/restraunt.jpg'}
              alt=""
              className="img-arrows main-services-block_img"
            />
            <div className="main-services-block_it-block-right">
              <p className="main-services-block_it-text">
                Насладитесь непревзойденным кулинарным опытом и нашим
                эксклюзивным гостеприимством.
              </p>
              {/* <!-- <a className="button-large button-right">Узнать больше</a> --> */}
            </div>
          </div>
        </section>

        <section className="main-services-block" id="restaurant16">
          <h3 className="services-block-title underline-title">Ресторан</h3>          
          <div className="main-services-block_it">
            <div className="main-services-block_it-block-left">
              <p className="main-services-block_it-text">
                Погрузитесь в изысканную атмосферу нашего ресторана, где
                роскошь сочетается с великолепным видом. Откройте для себя
                бесконечный мир вкусов и насладитесь неповторимым
                гастрономическим путешествием.
              </p>
              {/* <!-- <a className="button-large button-left">Узнать больше</a> --> */}
            </div>
            <img
              src={'./media/img/services/restraunt2.jpg'}
              alt=""
              className="img-arrows main-services-block_img"
            />
          </div>
        </section>

        <section className="main-services-block" id="lobbybar">
          <h3 className="services-block-title underline-title">
            Лобби бар
          </h3>
          <div className="main-services-block_it">
            <img
              src={'./media/img/services/lobbybar.jpg'}
              alt=""
              className="img-arrows main-services-block_img"
            />
            <div className="main-services-block_it-block-right">
              <p className="main-services-block_it-text">
                Добро пожаловать в наш уютный и роскошный бар, где каждая
                деталь создана для того, чтобы порадовать ваше воображение и
                окунуть вас в атмосферу настоящего релакса.
              </p>
              {/* <a className="button-large button-right">Узнать больше</a> */}
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}

export default ServicesComponent;
