import React from 'react';

function FooterComponent() {
  return (
    <React.Fragment>
      <section className="footer-contacts-us">
        <div className="footer-contacts-us_hr"></div>
        <h1 className="footer-contacts-us_title">Lorem, ipsum dolor.</h1>
        <p className="footer-contacts-us_text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Reprehenderit praesentium laudantium sapiente facilis ipsa placeat
          ullam molestiae voluptatibus asperiores, beatae provident sequi,
          accusantium tempora. Necessitatibus asperiores incidunt at nemo
          maiores qui. Omnis?
        </p>
      </section>

      <footer>
        <div className="container footer-flex">
          <section className="copyright">
            <a href="/" className="footer-logo">
              <img
                src={'./media/img/icons/logo.png'}
                alt="logo"
                className="footer-logo-img"
              />
            </a>
            <p className="copyright-text">© Selbi-Boyly {new Date().getFullYear()}</p>
          </section>
          <section className="footer-info-block">
            <ul className="footer-info-block_ul">
              <li className="footer-info-block_ul_li">
              Туркменистан, Национальная туристическая зона «Аваза»
              </li>
              <li className="footer-info-block_ul_li">
                <a href="tel:+99312">Тел: (800243) 5-76-35, 5-76-37</a>
              </li>
            </ul>
          </section>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default FooterComponent;
