import React, { useState, useEffect } from 'react';

import { apiUrl } from '../config';

import BookingFormComponent from './BookingFormComponent'
import { useLocation, useNavigate } from 'react-router-dom';

function BookingComponent() {
  const [bookingFormVisible, setBookingFormVisible] = useState(false);
  const [rooms, setRooms] = useState(null)
  const [roomsStatus, setRoomsStatus] = useState([])
  const [searchResults, setSearchResults] = useState(null);
  const [searchResultsStatus, setSearchResultsStatus] = useState([]);

  const [selectedRoom, setSelectedRoom] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Получаем параметры из URL
    const params = new URLSearchParams(location.search);
    const message = params.get('message');

    // Если параметр message существует, можно обработать его
    if (message) {
      alert(`Сообщение от банка: ${message}`);
      // Убираем параметр из адресной строки после обработки
      navigate('/', { replace: true });
    }
  }, [location, navigate]);

  // Получение данных с API при загрузке компонента
  useEffect(() => {
    fetch(`${apiUrl}/api/get-rooms`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        if(data.request.status === 0) {
          setRooms(data.request.data)
        } else if (data.request.status === 1) {
          setRooms([])
          setRoomsStatus(data.request.message)
        } else {
          console.error('Ошибка при получении данных:', data.request.message)
          setRooms([])
        }
      })
      .catch(error => {
        console.error('Error fetching rooms:', error)
        setRooms([])
      });
  }, []);


  const handleRoomChange = (e) => {
    const { name, value } = e.target;
    if (name === 'rid') {
      setSelectedRoom(value);
    } else if (name === 'cin') {
      setCheckInDate(value);
    } else if (name === 'cout') {
      setCheckOutDate(value);
    }
  };

  function shRBInner(key) {
    let rb_inner = document.getElementById('results-block-inner')
    switch (key) {
      case 'show':
        if(rb_inner) {
          rb_inner.classList.remove('d-none')
        }
        break;
      case 'hide':
        if(rb_inner) {
          rb_inner.classList.add('d-none')
        }
        break;
      default:
        return null
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Собираем данные для отправки
    const bookingData = {
      rid: selectedRoom,
      cin: checkInDate,
      cout: checkOutDate,
    };

    try {
      // Отправка POST-запроса на сервер
      const response = await fetch(`${apiUrl}/api/search-rooms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingData),
      });
      const data = await response.json();
      if(data.request.status === 0) {
        setSearchResults(data.request.data)
        shRBInner('show')
        document.getElementById('results-block-inner-status').classList.add('d-none')
      } else {
        console.error('Ошибка при получении данных:', data.request.message)
        setSearchResultsStatus(data.request.message)
        shRBInner('hide')
        document.getElementById('results-block-inner-status').classList.remove('d-none')
      }
      document.getElementById('results-block').classList.remove('d-none')

    } catch (error) {
      console.error('Ошибка сети:', error);
    }
  };

  // Отображение формы для перехода на страницу банка
  const handleSubmitToBookingForm = () => {
      setBookingFormVisible(true); // Show the booking form
  }

  const closeBookingForm = () => {
    setBookingFormVisible(false); // Hide the booking form
  };

  return (
    <React.Fragment>
      <div className="container container-rooms-choosen">
        <h1 className="container-rooms-choosen__title" id="book_room">
          Бронирование
        </h1>
        <form className="search-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="rid">Тип номера:</label>
            <select
              id="rid"
              name="rid"
              required
              value={selectedRoom}
              onChange={handleRoomChange}
            >
              {rooms ? (
                <React.Fragment>
                  <option defaultChecked>Выберите</option>
                  {rooms.map((room) => (
                      <option key={room.id} value={room.id}>
                          {room.room_type}
                      </option>
                  ))}
                </React.Fragment>
                ) : (
                  <option disabled defaultChecked>{roomsStatus}</option>
                )
              }
            </select>
          </div>
          <div className="form-group__dates">
            <div className="form-group">
              <label htmlFor="cin">Бронь с:</label>
              <input
                type="date"
                id="cin"
                name="cin"
                value={checkInDate}
                onChange={handleRoomChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="cout">до:</label>
              <input
                type="date"
                id="cout"
                name="cout"
                value={checkOutDate}
                onChange={handleRoomChange}
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="abutton"
            id="button-form-group__pass">
              Поиск
          </button>
        </form>
      </div>

      <div className="container container-rooms-result d-none" id='results-block'>
        <h2 className="container-rooms-result__title">Результаты поиска</h2>
        <h4 className='text searching-status-booking d-none' id='results-block-inner-status'>{searchResultsStatus}</h4>
        {searchResults ? (
          <div className="room-result" id='results-block-inner'>
            {/* <input
              type="text"
              value={searchResults.room_id}
              hidden
            /> */}
            <h3 className="room-result__type">{searchResults.room_type}</h3>
            
            {/* {searchResults.images.map((img, index) => (
              <img key={index} src={img} alt={`Room image ${index + 1}`} />
            ))} */}

            <div className="room-result__images">
              <img
                src="./media/img/rooms/room1_1-lux.jpg"
                alt="Одноместный номер изображение 1"
              />
              <img
                src="./media/img/rooms/room1_2-lux.jpg"
                alt="Одноместный номер изображение 2"
              />
              <img
                src="./media/img/rooms/room1_3-lux.jpg"
                alt="Одноместный номер изображение 3"
              />
            </div>

            <p className="room-result__price">
              Цена бронирования: <strong>{searchResults.booking_price} TMT</strong>
            </p>
            <p className="room-result__price">
              Цена на сутки: <strong>{searchResults.price} TMT</strong>
            </p>

            <p className="room-result__guests">
              Тип номера: <strong>{searchResults.room_type}</strong>
            </p>

            <p className="room-result__amenities">
              Удобства: Wi-Fi, Кондиционер, Телевизор
            </p>

            <p className="room-result__floor">
              Количество гостей которые могут разместиться в номере: <strong>{searchResults.capacity}</strong>
            </p>

            <p className="room-result__floor">
              Количество комнат в номере: <strong>{searchResults.rooms_count}</strong>
            </p>

            <p className="room-result__floor">
              На данный момент доступно минимум<strong> {searchResults.available_rooms} </strong>номера
            </p>

            <p className="room-result__description">
              Описание: {searchResults.description}
            </p>
            <button className="button-booking to-book-room" onClick={() => handleSubmitToBookingForm()}>
              Забронировать
            </button>
          </div>
        ) : null}
      </div>
      {bookingFormVisible && <BookingFormComponent data={searchResults} onClose={closeBookingForm} />} 
    </React.Fragment>
  )
}

export default BookingComponent;