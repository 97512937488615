import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../../config';

function Protected({ element, requiredRoles=[] }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const token = localStorage.getItem('accessToken'); // Получаем токен из localStorage
      if (!token) {
        navigate('/404'); // Если токен отсутствует, перенаправляем на страницу 404
        return;
      }

      try {
        await axios.get(`${apiUrl}/api/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Отправляем токен в заголовке
          },
        });
        setIsAuthenticated(true); // Если токен валидный, устанавливаем статус аутентификации
      } catch (error) {
        navigate('/404'); // В случае ошибки перенаправляем на страницу 404
      }
    };

    fetch();
  }, [navigate]);

  useEffect(() => {
    const userRole = localStorage.getItem('asd'); // Получаем токен из localStorage
    // Проверка прав доступа после получения роли
    // Убедитесь, что userRole и requiredRoles одинакового типа (например, обе строки или оба числа)
    if (!requiredRoles.some(role => String(role) === String(userRole))) {
      navigate('/404'); // Доступ запрещен
    }
  }, [navigate, requiredRoles]);

  // Если пользователь не аутентифицирован, ничего не показываем
  if (!isAuthenticated) {
    return null;
  }


  // Если аутентификация прошла успешно, рендерим переданный компонент
  return element;
}

export default Protected;
