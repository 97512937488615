import React, { useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';

const UserEdit = ({ user, rolesData, onClose }) => {
    const [messageEditData, setMessageEditData] = useState([])
    const [editUser, setEditUser] = useState({
        ...user,
        "old_password": '',
        "new_password": ''
    });
    const [roles] = useState(rolesData);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const token = localStorage.getItem('accessToken');
    const sendData = async (dataEdit) => {
        const data = await fetchData(`${apiUrl}/api/sys/edit-user`, "POST", token, {body: JSON.stringify(dataEdit)})
        if (data.ok) {
            setMessageEditData(data.request.message)
        }
    }

    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <header className="system-header">Редактирование</header>
                <form className="system-form">
                    <div className="system-input-box">
                        <label>Login</label>
                        <input required placeholder="Имя пользователя" value={editUser.username} type="text" name='username' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Старый</label>
                        <input placeholder="пароль" value={editUser.old_password} type="password" name="old_password" onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Новый</label>
                        <input placeholder="пароль" value={editUser.new_password} type="password" name="new_password" onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box system-address">
                        <label>Роль</label>
                        <div className="system-column">
                            <div className="system-select-box">
                                <select required value={editUser.role_id || ''} onChange={handleInputChange} name='role_id'>
                                    {roles && roles.length > 0 ? (
                                        roles.map((role) => (
                                            <option key={role.id} value={role.id}>{role.role_name}</option>
                                        ))
                                    ) : (
                                        <option value="">Нет ролей</option>
                                    )}

                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='flex-btns'>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(editUser)}>Сохранить</button>
                    </div>
                    {messageEditData ? (
                            <span className='error-message'>{messageEditData}</span>
                    ) : (null)}
                </form>
                </section>
            </div>
    );
}

export default UserEdit;