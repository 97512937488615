import React from 'react';
import '../styles/css/maintenancemode.css'; // импортируем CSS файл

const MaintenanceMode = () => {
  return (
    <div className="maintenance-container">
      <h1 className="maintenance-title">Проводятся технические работы</h1>
      <p className="maintenance-message">Мы временно недоступны.</p>
      <div className="maintenance-icon">🛠️</div>
    </div>
  );
};

export default MaintenanceMode;