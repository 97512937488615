import React, { useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';

const RoomEdit = ({ room, onClose }) => {
    const [messageEditData, setMessageEditData] = useState([])
    const [editRoom, setEditRoom] = useState(room);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditRoom((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const token = localStorage.getItem('accessToken');
    const sendData = async (dataEdit) => {
        const data = await fetchData(`${apiUrl}/api/sys/edit-room`, "POST", token, {body: JSON.stringify(dataEdit)})
        if (data.ok) {
            setMessageEditData(data.request.message)
        }
    }
    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <header className="system-header">Редактирование</header>
                <form className="system-form">
                    <div className="system-input-box">
                        <label>Тип номера</label>
                        <input required placeholder="Строка" value={editRoom.room_type} type="text" name='room_type' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Количество номеров</label>
                        <input required placeholder="Число" value={editRoom.count} type="number" name='count' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Описание</label>
                        <input placeholder="Описание" value={editRoom.description ? editRoom.description : '' } type="text" name='description' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Цена, летний сезон</label>
                        <input required placeholder="Число" value={editRoom.price_summer} type="number" name='price_summer' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Цена, зимний сезон</label>
                        <input required placeholder="Число" value={editRoom.price_winter} type="number" name='price_winter' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Максимальное количество гостей, которые могут разместиться в номере</label>
                        <input required placeholder="Число" value={editRoom.capacity} type="number" name='capacity' onChange={handleInputChange} />
                    </div>
                    <div className="system-input-box">
                        <label>Количество комнат в номере</label>
                        <input required placeholder="Число" value={editRoom.rooms_count} type="number" name='rooms_count' onChange={handleInputChange} />
                    </div>
                    <div className='flex-btns'>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(editRoom)}>Сохранить</button>
                    </div>
                    {messageEditData ? (
                            <span className='error-message'>{messageEditData}</span>
                    ) : (null)}
                </form>
                </section>
            </div>
    );
}

export default RoomEdit;