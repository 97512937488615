import { React, useEffect, useState } from 'react';
import { apiUrl } from '../../config'
import { fetchData } from '../../utils/api';
import { intervalUpdatingData } from '../../utils/dynamic';

function Dashboard() {
  const [dashboardInfo, setDashboardInfo] = useState([])

  const fetch = async () => {
    const token = localStorage.getItem('accessToken');
    const response = await fetchData(`${apiUrl}/api/sys/get-dashboard-info`, "GET", token)
    if (response.request.status)
      console.error("Ошибка загрузки данных:", response.request.message);
    else {
      setDashboardInfo(response.request.data);
    }
  };
  useEffect(() => {
    fetch();
    const interval = setInterval(() => {
      setTimeout(() => {
        fetch()
      }, 3000)
    }, intervalUpdatingData)
    return () => clearInterval(interval);

  }, []);

  return (
    <section className="system-dashboard">
      <div className="card">
        <h2>Действующих бронирований</h2>
        <p>{dashboardInfo.active_bookings_count}</p>
      </div>
      <div className="card">
        <h2>Свободные номера</h2>
        <p>{dashboardInfo.free_rooms_count}</p>
      </div>
      <div className="card">
        <h2>Доход за действующие бронирования</h2>
        <p>{dashboardInfo.sum} TMT</p>
      </div>
      <div className="card">
        <h2>Процент занятости</h2>
        <p>{dashboardInfo.occupanty_rate}%</p>
      </div>
    </section>
  );
}

export default Dashboard;
