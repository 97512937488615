import React, { useState } from 'react';

function HeaderComponent() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  // this.getElementById('tobook_room').addEventListener('click', function () {
  //   document
  //     .getElementById('book_room')
  //     .scrollIntoView({ behavior: 'smooth', block: 'center' });
  // });
  // this.getElementById('toservices').addEventListener('click', function () {
  //   document
  //     .getElementById('services')
  //     .scrollIntoView({ behavior: 'smooth', block: 'center' });
  // });
  // this.getElementById('toreview').addEventListener('click', function () {
  //   document
  //     .getElementById('review')
  //     .scrollIntoView({ behavior: 'smooth', block: 'center' });
  // });

  return (
    <React.Fragment>
      <header className="new-header">
        <div className="wrapper">
          <div className='wrapper__flex'>
            <div className="new-header__logo">
              <a href="/">
                <img src={'./media/img/icons/logo.png'} alt="Logo" />
              </a>
            </div>

            <nav className={`new-header__nav ${menuOpen ? 'active' : ''}`}>
              <ul>
                <li><a className='hover-main-color' href="#about">О нас</a></li>
                <li><a className='hover-main-color' href="#services">Услуги</a></li>
              </ul>
            </nav>
          </div>

          <div className="new-header__actions">
            <button className="abutton">Забронировать номер</button>
            <a href="tel:(800) 243 5-76-35" className="phone-link hover-main-color">(800) 243 5-76-35</a>
          </div>
          <div className={`menuButton ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
              <span className="top"></span>
              <span className="mid"></span>
              <span className="bot"></span>
          </div>
        </div>

        <div className={`new-header__mobile-menu ${menuOpen ? 'active' : ''}`}>
          <nav>
            <ul className='new-header__actions'>
              <li><a className='hover-main-color' href="#about">О нас</a></li>
              <li><a className='hover-main-color' href="#services">Услуги</a></li>
              <li><a className='hover-main-color' href="tel:(800) 243 5-76-35">(800) 243 5-76-35</a></li>
              <li><button className="abutton">Забронировать номер</button></li>
            </ul>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
}

export default HeaderComponent;
