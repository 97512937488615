// src/utils/api.js
export async function fetchData(url, method = 'GET', token = '', options = {}) {
    try {
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...(token && { "Authorization": `Bearer ${token}` }),
                ...options.headers,
            },
            ...options
        });

        if (!response.ok) {
            console.log(`Ошибка: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Ошибка при запросе:', error);
        throw error;
    }
}

export function formatDate(dateInput) {
    // Разделяем дату и время
    const [datePart] = dateInput.split(" ");
    const [day, month, year] = datePart.split(".");

    // Преобразуем в формат yyyy-mm-dd
    return `${year}-${month}-${day}`;
}