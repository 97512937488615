import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData, formatDate } from '../../../utils/api';


const BookingEdit = ({ booking, onClose }) => {
    const [rooms, setRooms] = useState([])
    const [selectedPrice, setSelectedPrice] = useState('')
    const [messageStatusBook, setMessageStatusBook] = useState('')
    const [viewBooking] = useState({
        ...booking,
        available_from: formatDate(booking.available_from),
        available_to: formatDate(booking.available_to)
    });

    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }

    useEffect(() => {
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && viewBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(viewBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, viewBooking.room_id])

    const set_status = async (bookginId) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiUrl}/api/sys/set-statusbook?booking_id=${bookginId}&status=0`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
            });
            const data = await response.json();
            if (response.ok) {
                setMessageStatusBook(data.request.message)
            } else {
                setMessageStatusBook(data.request.message)
                console.error("Ошибка загрузки данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
      }
    return (
        <div className='system-background-hide'>
            <section className="system-container">
                <header className="system-header">Просмотр</header>
                <div className="system-form">
                    <div className="system-input-box">
                        <label>Полное имя</label>
                        <input disabled placeholder="Имя/Фамилия" value={viewBooking.full_name} type="text" name='full_name' />
                    </div>
                    <div className="system-input-box">
                        <label>Телефон</label>
                        <input disabled placeholder="Enter phone number" value={viewBooking.phone_number} type="telephone" name="phone_number" />
                    </div>
                    <div className="system-input-box">
                        <label>Почта</label>
                        <input disabled placeholder="Enter phone number" value={viewBooking.email} type="telephone" name="email" />
                    </div>
                    <div className="system-column">
                        <div className="system-input-box">
                            <label>Начало брони</label>
                            <input disabled value={viewBooking.available_from} placeholder="Заезд" type="date" name='available_from' />
                        </div>
                        <div className="system-input-box">
                            <label>Окончание брони</label>
                            <input disabled value={viewBooking.available_to} placeholder="Выезд" type="date" name='available_to' />
                        </div>
                    </div>
                    <div className="system-input-box system-address">
                        <label>Тип номера</label>
                        <div className="system-column">
                            <div className="system-select-box">
                                <select disabled value={viewBooking.room_id || ''} name='room_id'>
                                    {rooms && rooms.length > 0 ? (
                                        rooms.map((room) => (
                                            <option key={room.id} value={room.id}>{room.room_type}</option>
                                        ))
                                    ) : (
                                        <option value="">Нет номеров</option>
                                    )}
                                </select>
                            </div>
                            <input disabled placeholder={selectedPrice || "Цена не доступна"} type="text" />
                        </div>
                    </div>
                    {messageStatusBook}
                    <div className='flex-btns'>
                        {booking.status === 1 ? (
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => set_status(viewBooking.id)}>Подтвердить оплату</button>
                        ):(null)}
                        <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BookingEdit;