import React from 'react';

function Header() {


  return (
    <header className='system-header'>
      <h1>Дашборд</h1>
      <div className="user-info">
        <p>Привет, Администратор</p>
        <a href="#">Выход</a>
      </div>
    </header>
  );
}

export default Header;
