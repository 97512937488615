// PageNotFound.js
import React, { useEffect, useState } from 'react';

const PageNotFound = () => {
  const [transformStyle, setTransformStyle] = useState({ transform: 'translate(0%, 0%)' });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const pageX = window.innerWidth;
      const pageY = window.innerHeight;

      // Расчет осей
      const mouseY = event.pageY;
      const yAxis = ((pageY / 2 - mouseY) / pageY) * 300;
      
      const mouseX = event.pageX / -pageX;
      const xAxis = -mouseX * 100 - 100;

      setTransformStyle({ transform: `translate(${xAxis}%, -${yAxis}%)` });
    };

    // Добавляем обработчик мыши
    document.addEventListener('mousemove', handleMouseMove);

    // Удаляем обработчик при размонтировании компонента
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);
  return (
    <div className='page-not-found-body'>
      <div className="page-not-found-box">
        <div className="page-not-found-box__ghost">
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          <div className="symbol"></div>
          
          <div className="page-not-found-box__ghost-container">
            <div className="page-not-found-box__ghost-eyes" style={transformStyle}>
              <div className="page-not-found-box__eye-left"></div>
              <div className="page-not-found-box__eye-right"></div>
            </div>
            <div className="page-not-found-box__ghost-bottom">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="page-not-found-box__ghost-shadow"></div>
        </div>
        <div className="page-not-found-box__description">
          <div className="page-not-found-box__description-container">
            <div className="page-not-found-box__description-title">Упссс!</div>
            <div className="page-not-found-box__description-text">Извините страницы по заданному адресу не существует.</div>
          </div>
          <a href="/" className="page-not-found-box__button">На главную</a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;