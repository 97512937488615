import React from 'react';
import '../styles/css/styles-cms.css';

import SideBar from '../components/system/SideBar';
import Header from '../components/system/Header';
import MainContent from '../components/system/MainContent';
import Users from './system/Users';
import Rooms from './system/Rooms';
import ExpiredBookings from '../components/system/bookings/ExpiredBookings';
import CanceledBookings from '../components/system/bookings/CanceledBookings';

function System({ type }) {
  return (
    <React.Fragment>
      <div className='system-body'>
        <SideBar />
        <div className="system-main-content">
          <Header />
          {type === "main" && <MainContent />}
          {type === "expiredBookings" && <ExpiredBookings />}
          {type === "canceledBookings" && <CanceledBookings />}
          {type === "users" && <Users />}
          {type === "rooms" && <Rooms />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default System;
