import React from 'react';

const AboutComponent = () => {
  return (
    <section className="about-section" id='about'>
      <div className="about-container">
        <h2 className="about-title">О нас</h2>
        <p className="about-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro, officia cum culpa quos ratione dolor, in quasi, laborum quaerat recusandae omnis. Asperiores quaerat qui atque placeat repellat sunt consectetur fugit ducimus eos cum laudantium perspiciatis modi eius doloremque, tempora dolores iusto tenetur dolorum eveniet eum. Error excepturi culpa, natus nobis sint rem aut laborum eveniet quidem ipsam iste enim, saepe aliquid, quos ipsum dolorem! Unde dolore dolores quisquam eligendi non, hic in laborum voluptate quibusdam rerum, culpa deserunt corporis voluptatem reprehenderit velit! Perferendis libero non nostrum vel quos voluptas necessitatibus quo consequatur, consectetur vero ducimus officiis tempore debitis hic eum molestiae aut voluptatum laboriosam voluptatem unde ullam eaque. Fugiat quidem sunt minima perferendis voluptas sed ut ipsum sint debitis! Reprehenderit, ut. Et nulla ex laborum natus autem sequi iste eos itaque recusandae. Obcaecati ex iure ipsam quis quidem facere eaque dolor, officiis vel ducimus. Quibusdam error cupiditate doloribus eius aliquam magnam, inventore facilis sit, temporibus amet quidem neque fugiat illum culpa dignissimos perspiciatis dolorem distinctio maxime impedit vitae! Doloribus ducimus adipisci esse consequatur?
        </p>
      </div>
    </section>
  );
};

export default AboutComponent;