import React from 'react';

import { hideElemByRole } from '../../utils/dynamic';
function SideBar() {


  return (
    <div className="system-sidebar">
      <div className="logo">HotelCMS</div>
      <nav>
        <ul>
          <li><a href="/system">Активные бронирования</a></li>
          <li><a href="/system/expired-bookings">Просроченные</a></li>
          <li><a href="/system/canceled-bookings">Отмененные</a></li>
          <li><a href="/system/rooms">Номера</a></li>
          {hideElemByRole(2) && (
            <li><a href="/system/users">Пользователи</a></li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default SideBar;
