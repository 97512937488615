import React from 'react';
import '../styles/css/index.css';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

import SliderComponent from '../components/SliderComponent';
import BookingComponent from '../components/BookingComponent';
import AboutUsComponent from '../components/AboutUsComponent';
import ServicesComponent from '../components/ServicesComponent';

function Home() {

    return (
      <React.Fragment>
        <HeaderComponent />
        <SliderComponent />
        <BookingComponent />
        <AboutUsComponent />
        <ServicesComponent />
        <FooterComponent />
      </React.Fragment>
    );
}

export default Home;
