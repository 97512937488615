// данные для динамичности
export const intervalUpdatingData = 5000 // Интервал в мс для обновления данных


export const hideElemByRole = (role = 2) => {
    const userRole = localStorage.getItem('asd'); // Получаем токен из localStorage
    if (String(role) === String(userRole)) {
        return false
    }
    return true
}

export const showStatusBooking = (status) => {
    switch (status) {
        case 0:
            return "Оплачено" 
        case 1:
            return "Оплата при заселении"
        case 3:
            return "Отменено"
        default:
            break
    }
}