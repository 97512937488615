// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';

import MaintenanceMode from './components/MaintenanceMode';
import Home from './templates/Home';
import System from './templates/System';
import PageNotFound from './templates/PageNotFound';
import Login from './components/system/Login';
import Protected from './components/system/Protected';

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.className = 'home-background';
    } else if (location.pathname === '/system') {
      document.body.className = 'system-background';
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {/* Active bookings */}
      <Route path="/system" element={<Protected element={<System type="main" />} requiredRoles={[1, 2]} />} />
      {/* All bookings */}
      <Route path="/system/expired-bookings" element={<Protected element={<System type="expiredBookings" />} requiredRoles={[1, 2]} />} />
      <Route path="/system/canceled-bookings" element={<Protected element={<System type="canceledBookings" />} requiredRoles={[1, 2]} />} />
      <Route path="/system/rooms" element={<Protected element={<System type="rooms" />} requiredRoles={[1, 2]} />} />
      <Route path="/system/users" element={<Protected element={<System type="users" />} requiredRoles={[1]} />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

const App = () => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsUnderMaintenance(false), 5000);
    return () => clearTimeout(timer); // Clear timer on unmount
  }, []);

  return (
    <React.Fragment>
      {isUnderMaintenance ? (
        <MaintenanceMode />
      ) : (
        <Router>
          <AppContent />
        </Router>
      )}
    </React.Fragment>
  );
};

export default App;
